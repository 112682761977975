<template>
    <div>
        <rockol-awards-header></rockol-awards-header>
        <rockol-awards-footer></rockol-awards-footer>

        <div class="fixed inset-0">
            <div class="absolute inset-0 bg-current-theme">
                <img
                    src="./../../assets/img/bg-2021.png"
                    class="mix-blend-luminosity w-full h-full object-cover">
            </div>
            <div class="absolute inset-0 bg-black opacity-70"></div>
        </div>

        <div class="app-main-view relative" :class="$store.getters.withFooter">
            <div class="px-6 sm:px-0"><PollDisclaimer></PollDisclaimer></div>
            <iubenda-consent-links></iubenda-consent-links>
        </div>

    </div>
</template>

<script>
import Header from '../page-components/Header.vue';
import Footer from "../page-components/Footer";
import PollDisclaimer from "../page-components/PollDisclaimer";
import IubendaConsentLinks from "../shared/IubendaConsentLinks";

export default {
    props: ['componentToLoad', 'componentProps'],
    components: {
        IubendaConsentLinks,
        PollDisclaimer,
        rockolAwardsHeader: Header,
        rockolAwardsFooter: Footer
    },
}
</script>

